<!-- eslint-disable camelcase -->
<template>
	<splash-screen :loaded="this.$store.getters.isFirstSplashScreenHidden" />
	<header-layout
		@open-bookings="openBookings = true"
		@menu-open="menuOpenListener"
	></header-layout>
	<!--  Main website content -->
	<div :class="{ fixed: routerViewFixed }">
		<placeholder
			:show="displayPlaceholder"
			:class="'c-grainy'"
		></placeholder>
		<router-view
			:key="$route.fullPath"
			@contentLoaded="handleContentLoaded"
			@displayPlaceholder="handleDisplayPlaceholder"
		/>
	</div>

	<footer-layout v-if="!hideFooter" @open-bookings="openBookings = true"></footer-layout>

	<fresh-desk-chat-bot v-if="chatBotOpened" />

	<cookie-banner @tracking-agreed="enableTracking()"></cookie-banner>
	<booking-widget
    v-if="!hideBookingWidget"
		:open="openBookings"
		@close-bookings="openBookings = false"
	></booking-widget>
</template>

<script>
// @ is an alias to /src
import { XIcon } from '@heroicons/vue/solid';
import HeaderLayout from '@/components/layouts/HeaderLayout.vue';
import FooterLayout from '@/components/layouts/FooterLayout.vue';
import BookingWidget from '@/components/BookingWidget.vue';
import FreshDeskChatBot from '@/components/FreshDeskChatBot.vue';
import CookieBanner from '@/components/CookieBanner.vue';
import SplashScreen from '@/components/layouts/SplashScreen.vue';
import Placeholder from './components/layouts/Placeholder.vue';
import wordpress from './plugins/wordpress';
import localStore from './plugins/localStore';
// import exponea from '@/plugins/exponea''

export default {
	name: 'App',
	data() {
		return {
			openBookings: false,
			routerViewFixed: false,
			contentLoadedOnFirstPage: false,
			displayPlaceholder: false,
		};
	},
	components: {
		XIcon,
		CookieBanner,
		FreshDeskChatBot,
		BookingWidget,
		HeaderLayout,
		FooterLayout,
		SplashScreen,
		Placeholder,
	},
	computed: {
		chatBotOpened() {
			return this.$store.getters.hasChatBotBeenRequested;
		},
    hideFooter() {
      return this.$route.meta?.hideFooter

    },
    hideBookingWidget() {
      return this.$route.meta?.hideBookingWidget
    }
	},
	methods: {
		initMSClarity(environmentScript) {
			const script = document.createElement('script');
			script.innerHTML = environmentScript;
			document.head.appendChild(script);
		},
		initExponea(trackVisits) {
			/* eslint-disable */
			!(function (e, n, t, i, r, o) {
				function s(e) {
					if ('number' != typeof e) return e;
					var n = new Date();
					return new Date(n.getTime() + 1e3 * e);
				}
				var a = 4e3,
					c = 'xnpe_async_hide';
				function p(e) {
					return e.reduce(
						function (e, n) {
							return (
								(e[n] = function () {
									e._.push([n.toString(), arguments]);
								}),
								e
							);
						},
						{ _: [] }
					);
				}
				function m(e, n, t) {
					var i = t.createElement(n);
					i.src = e;
					var r = t.getElementsByTagName(n)[0];
					return r.parentNode.insertBefore(i, r), i;
				}
				function u(e) {
					return (
						'[object Date]' === Object.prototype.toString.call(e)
					);
				}
				(o.target = o.target || 'https://api.exponea.com'),
					(o.file_path =
						o.file_path || o.target + '/js/exponea.min.js'),
					(r[n] = p([
						'anonymize',
						'initialize',
						'identify',
						'getSegments',
						'update',
						'track',
						'trackLink',
						'trackEnhancedEcommerce',
						'getHtml',
						'showHtml',
						'showBanner',
						'showWebLayer',
						'ping',
						'getAbTest',
						'loadDependency',
						'getRecommendation',
						'reloadWebLayers',
						'_preInitialize',
						'_initializeConfig',
					])),
					(r[n].notifications = p([
						'isAvailable',
						'isSubscribed',
						'subscribe',
						'unsubscribe',
					])),
					(r[n].segments = p(['subscribe'])),
					(r[n]['snippetVersion'] = 'v2.7.0'),
					(function (e, n, t) {
						(e[n]['_' + t] = {}),
							(e[n]['_' + t].nowFn = Date.now),
							(e[n]['_' + t].snippetStartTime =
								e[n]['_' + t].nowFn());
					})(r, n, 'performance'),
					(function (e, n, t, i, r, o) {
						e[r] = {
							sdk: e[i],
							sdkObjectName: i,
							skipExperiments: !!t.new_experiments,
							sign:
								t.token +
								'/' +
								(o.exec(n.cookie) || ['', 'new'])[1],
							path: t.target,
						};
					})(r, e, o, n, i, RegExp('__exponea_etc__' + '=([\\w-]+)')),
					(function (e, n, t) {
						m(e.file_path, n, t);
					})(o, t, e),
					(function (e, n, t, i, r, o, p) {
						if (e.new_experiments) {
							!0 === e.new_experiments &&
								(e.new_experiments = {});
							var l,
								f = e.new_experiments.hide_class || c,
								_ = e.new_experiments.timeout || a,
								g = encodeURIComponent(
									o.location.href.split('#')[0]
								);
							e.cookies &&
								e.cookies.expires &&
								('number' == typeof e.cookies.expires ||
								u(e.cookies.expires)
									? (l = s(e.cookies.expires))
									: e.cookies.expires.tracking &&
									  ('number' ==
											typeof e.cookies.expires.tracking ||
											u(e.cookies.expires.tracking)) &&
									  (l = s(e.cookies.expires.tracking))),
								l && l < new Date() && (l = void 0);
							var d =
								e.target +
								'/webxp/' +
								n +
								'/' +
								o[t].sign +
								'/modifications.min.js?http-referer=' +
								g +
								'&timeout=' +
								_ +
								'ms' +
								(l
									? '&cookie-expires=' +
									  Math.floor(l.getTime() / 1e3)
									: '');
							'sync' === e.new_experiments.mode &&
							o.localStorage.getItem(
								'__exponea__sync_modifications__'
							)
								? (function (e, n, t, i, r) {
										(t[r][n] =
											'<' +
											n +
											' src="' +
											e +
											'"></' +
											n +
											'>'),
											i.writeln(t[r][n]),
											i.writeln(
												'<' +
													n +
													'>!' +
													r +
													'.init && document.writeln(' +
													r +
													'.' +
													n +
													'.replace("/' +
													n +
													'/", "/' +
													n +
													'-async/").replace("><", " async><"))</' +
													n +
													'>'
											);
								  })(d, n, o, p, t)
								: (function (e, n, t, i, r, o, s, a) {
										o.documentElement.classList.add(e);
										var c = m(t, i, o);
										function p() {
											r[a].init ||
												m(
													t.replace(
														'/' + i + '/',
														'/' + i + '-async/'
													),
													i,
													o
												);
										}
										function u() {
											o.documentElement.classList.remove(
												e
											);
										}
										(c.onload = p),
											(c.onerror = p),
											r.setTimeout(u, n),
											(r[s]._revealPage = u);
								  })(f, _, d, n, o, p, r, t);
						}
					})(o, t, i, 0, n, r, e),
					(function (e, n, t) {
						var i;
						e[n]._initializeConfig(t),
							(null === (i = t.experimental) || void 0 === i
								? void 0
								: i.non_personalized_weblayers) &&
								e[n]._preInitialize(t),
							(e[n].start = function (i) {
								i &&
									Object.keys(i).forEach(function (e) {
										return (t[e] = i[e]);
									}),
									e[n].initialize(t);
							});
					})(r, n, o);
			})(document, 'exponea', 'script', 'webxpClient', window, {
				target: 'https://api.exponea.com',
				token: process.env.VUE_APP_EXPONEA_TOKEN,
				experimental: {
					non_personalized_weblayers: true,
				},
				new_experiments: { mode: 'async' },
				track: {
					visits: trackVisits,
					google_analytics: false,
				},
			});
			exponea.start();
			/* eslint-enable */
		},

		initFacebookPixel() {
			!(function (f, b, e, v, n, t, s) {
				if (f.fbq) return;
				n = f.fbq = function () {
					n.callMethod
						? n.callMethod.apply(n, arguments)
						: n.queue.push(arguments);
				};
				if (!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				t = b.createElement(e);
				t.async = !0;
				t.src = v;
				t.rel = 'preconnect';
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s);
			})(
				window,
				document,
				'script',
				'https://connect.facebook.net/en_US/fbevents.js'
			);

			fbq('init', '1435998153374276');
			fbq('track', 'PageView');
		},
		enableTracking() {
			this.$gtag.optIn();
			this.initFacebookPixel();
			this.initExponeaWithTrackingConsent();
		},
		initExponeaWithTrackingConsent() {
			this.initExponea(true);
		},
		menuOpenListener(menuOpen) {
			this.routerViewFixed = menuOpen;
		},
		initSiteData() {
			this.$store.dispatch('getCookieConsent').then((resp) => {
				if (resp.consent === 'true') {
					this.enableTracking();
					wordpress.countTrackingAcceptance({
						trackingStatus: 'alreadyAccepted',
					});
				} else if (resp.consent === 'false') {
					// We are always going to enable exponea, as some of it's functionality
					// classes as 'functional cookies' which are ok to use without consent
					// i.e. in the booking widget
					// any of exponea's functions that aren't 'functional' should be
					// wrapped in this 'getCookieConsent' check e.g. page view events.
					this.initExponea(false);
					wordpress.countTrackingAcceptance({
						trackingStatus: 'alreadyRejected',
					});
				} else if (resp.consent === null) {
					wordpress.countTrackingAcceptance({
						trackingStatus: 'rejected',
					});
					this.initExponea(false);
				}
			});

			// Vuex store ajax calls to get initial data
			this.$store.dispatch('getData');
			this.$store.dispatch('getFullPageData');
		},
		initUTM() {
			const params = new URLSearchParams(window.location.search);
			const utm_campaign = params.get('utm_campaign');
			const utm_source = params.get('utm_source');
			const utm_medium = params.get('utm_medium');

			const utm = {
				...(utm_campaign && { c: utm_campaign }),
				...(utm_source && { s: utm_source }),
				...(utm_medium && { m: utm_medium }),
			};

			if (Object.keys(utm).length > 0) {
				localStore.set('utm', utm, 604800); // 7 days
			}
		},
		handleContentLoaded() {
			if (!this.$store.getters.isFirstSplashScreenHidden) {
				// This set timeout is here to display the splash screen a second past loading
				// As per the request of the design team
				setTimeout(() => {
					this.$store.commit('hideFirstSplashScreen');
				}, 1000);
			}
			this.displayPlaceholder = false;
		},
		handleDisplayPlaceholder() {
			this.displayPlaceholder = true;
		},
		splashScreenFailsafe() {
			// In case there is a view that doesn't emit 'contentLoaded'
			// we will hide the splash screen / spinner after 3 secs
			setTimeout(() => {
				this.handleContentLoaded();
			}, 3000);
		},
		getNearbyRestaurants() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((pos) => {
					wordpress
						.getRestaurantsByDistance(
							pos.coords.latitude,
							pos.coords.longitude
						)
						.then((r) => r.json())
						.then((resp) => {
							this.$store.commit('setNearbyRestaurants', resp);
						});
				});
			}
		},
		getMenuCodeParam() {
			const params = new Proxy(
				new URLSearchParams(window.location.search),
				{
					get: (searchParams, prop) => searchParams.get(prop),
				}
			);
			this.$store.commit('initMenuCode', params.menucode);
		},
	},
	created() {
		// this.checkForUnsuportedBrowser()
		this.getMenuCodeParam();
		this.initSiteData();
		this.initUTM();
		this.getNearbyRestaurants();
		this.splashScreenFailsafe();

		if (process.env.NODE_ENV === 'development') {
			this.initMSClarity(
				'(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "hmrg868sn1");'
			);
		} else if (process.env.NODE_ENV === 'production') {
			this.initMSClarity(
				'(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "hk6jfmgiwx");'
			);
		}
	},
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
.xnpe_async_hide {
	opacity: 0 !important;
}
</style>
